@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
	body {
		@apply font-roboto-mono;
	}
	p {
		@apply font-poppins;
	}

	header {
		padding: 10px;
	}

	aside {
		padding: 10px;
	}

	@media (min-width: 640px) {
		header,
		aside {
			padding-left: 18px;
			padding-right: 18px;
			padding-top: 30px;
		}
		header {
			padding-top: 42px;
		}
	}

	@media (min-width: 1024px) {
		header,
		aside {
			padding-left: 60px;
			padding-right: 60px;
			padding-top: 40px;
		}
		header {
			padding-top: 80px;
		}
	}
}
